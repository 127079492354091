<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 login">
    <static-text />

    <div
      class="m-0 w-full item-direction md:my-16 md:mx-auto md:w-3/5 lg:mx-0 lg:w-4/5 xl:max-w-md"
    >
      <custom-card title="Credenciais de acesso" subtitle="">
        <ValidationObserver v-slot="{ invalid }">
          <form>
            <ValidationProvider
              name="E-mail"
              rules="required|email"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="E-mail"
                v-model="email"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-if="Newe"
              name="Senha"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-8"
                type="password"
                size="large"
                @keyup.enter="loadLogin()"
                label="Senha"
                v-model="password"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-else
              name="Senha"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-8"
                type="password"
                size="large"
                @keyup.enter="loadLogin()"
                label="Senha"
                v-model="password"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <div class="flex flex-wrap justify-end my-5">
              <router-link to="/recuperacao-da-senha">
                <b>Esqueceu a senha?</b></router-link
              >
            </div>

            <vs-button
              :disabled="invalid"
              @click.prevent="loadLogin()"
              class="w-full mt-8 uppercase"
              size="large"
              >Entrar</vs-button
            >
            <!-- <vs-button
              :disabled="invalid"
              @click.prevent="goToAdvisor()"
              class="w-full mt-8 uppercase"
              size="large"
              >Sou uma Assessoria</vs-button
            > -->

            <div class="flex flex-wrap justify-center mt-12" v-if="!Mapfre">
              Não tem acesso?
              <router-link to="/iniciar-cadastro"
                ><b class="pl-2">Cadastrar-se</b></router-link
              >
            </div>
            <span v-show="enableAdvisorInsurance">
              <div class="flex flex-wrap justify-center mt-2">
                É uma assessoria?
                <router-link
                  :to="{
                    name: 'page-login-assessor',
                    params: { companyDns: $route.params.companyDns }
                  }"
                  ><b class="pl-2">Clique aqui</b></router-link
                >
              </div>
            </span>
          </form>
        </ValidationObserver>
      </custom-card>
    </div>

    <soliciticitar-token
      :dados="{ email: email, password: password }"
      :showModal="selectAuthTypeModal"
      @closeModal="selectAuthTypeModal = false"
    />
  </div>
</template>

<script>
import CustomCard from "../../layouts/components/custom-card/CustomCard.vue";
import StaticText from "../../layouts/components/static-text/StaticText.vue";
import SoliciticitarToken from "./components/SoliciticitarToken.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import environment from "../../environment";

export default {
  components: {
    CustomCard,
    StaticText,
    SoliciticitarToken
  },
  data() {
    return {
      email: "",
      password: "",
      applicationConfig: {},
      selectAuthTypeModal: false,
      mapfre: false,
      Newe: false
    };
  },
  computed: {
    ...mapGetters("auth", ["token", "userInfo", "authType"]),
    enableAdvisorInsurance() {
      return this.applicationConfig.enableAdvisorInsurance;
    },

    isAuth2F() {
      return this.authType == "TwoFactor";
    }
  },
  methods: {
    ...mapActions("auth", ["login", "generateToken", "loginLegacy"]),

    loadLogin() {
      this.$appInsights.trackEvent({ name: "login", properties: { place: "page-login", action: "button-click", slug: "login" }});
      if (!this.isAuth2F) this.simpleLogin();
      else this.selectAuthTypeModal = true;
    },

    simpleLogin() {
      let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
      let shouldUseLegacyLogin = portalConfig.enableLegacyLogin

      if(shouldUseLegacyLogin) {
        this.submitFormWithLegacyLogin()
      } else {
        this.submitForm()
      }
    },

    submitForm() {
      this.$vs.loading();
      let payload = {
        grant_type: "password",
        Username: this.email,
        Password: this.password
      };

      return this.login(payload)
        .then(data => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.$onpoint.errorModal(
            ((errors.response || {}).data.Message ||
              "Não foi possível realizar o Login."
          ))
        })
        .finally(() => this.$vs.loading.close());
    },

    submitFormWithLegacyLogin() {
      this.$vs.loading();
      let payload = {
        grant_type: "password",
        Username: this.email,
        Password: this.password
      };

      return this.loginLegacy(payload)
        .then(data => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.$onpoint.errorModal(
            ((errors.response || {}).data.Message ||
              "Não foi possível realizar o Login."
          ))
        })
        .finally(() => this.$vs.loading.close());
    }
  },
  beforeMount() {
    this.applicationConfig = environment.getApplicationConfig();
  },
  mounted() {
    localStorage.removeItem(environment.localStorageKeys.demoCompanyKey);
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-login",
      url: window.location.href
    });
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));

    if(portalConfig.redirectLogin) {
      window.location.replace(portalConfig.redirectLoginUrl)
    }

    let Mapfre = parseInt(environment.CompanyId.Mapfre);
    this.Mapfre = portalConfig.CompanyId == Mapfre ? true : false;

    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;
  }
};
</script>

<style scoped lang="scss">
::v-deep .vs-button--text {
  text-transform: uppercase;
}

.item-direction {
  justify-self: end;
}
</style>
